import { useEffect, useState } from "react";

function CapexOpex({ itemName, itemValue }: { itemName: string, itemValue: number }) {
    const [showData, setShowData] = useState(false);

    useEffect(() => {
        if (itemValue !== undefined) {
            setShowData(true);
        }
    }, [itemValue])

    return (
        <div className='capex-opex-box'>
            {showData &&
                <>
                    <div className='capex-opex-title'>{itemName}</div>
                    <div className="capex-opex-value">
                        <div className='capex-opex-num'>
                            {(itemValue / 1000 / 1000).toLocaleString('it-IT', { maximumFractionDigits: 1 })}
                        </div>
                        <span className="uom">M€</span>
                    </div>
                </>
            }
        </div>
    );
};

export default CapexOpex;