import { MouseEventHandler } from "react";

function SubmenuItem({ icon, itemTitle, className, onClick }: { icon: JSX.Element, itemTitle: string, className: string, onClick: MouseEventHandler }) {

    return (
        <div className={className} onClick={onClick}>
            <div className="item-icon">{icon}</div>
            <div className="item-title">{itemTitle}</div>
        </div>
    );
};

export default SubmenuItem;