import { MouseEventHandler, useEffect, useState } from "react";
import { ReactComponent as Leading } from '../assets/icons/leading.svg';
import { ReactComponent as Basic } from '../assets/icons/basic.svg';
import { ReactComponent as Lagging } from '../assets/icons/lagging.svg';

function CapabilityArea({ className, onClick, areaName, capData, activeStyle, indicator }: { className: string, onClick: MouseEventHandler, areaName: string, capData: any, activeStyle: string, indicator: string }) {
    const [showCapDetail, setShowCapDetail] = useState(false);

    useEffect(() => {
        if (Object.keys(capData).length > 0) {
            setShowCapDetail(true);
        }
    }, [capData])

    return (
        <div className={className} onClick={onClick}>
            <div className={activeStyle}>
                <div className="cap-name">{areaName}</div>
                {showCapDetail &&
                    <div className="cap-info-container">
                        <div className="cap-info">
                            <span className="total-num">
                                <p className="title">TOTAL</p>
                                <p className="value">{capData[areaName].total}</p>
                            </span>
                            <div className="core-box">
                                <span className="core-num">
                                    <p className="title">CORE</p>
                                    <p className="value">{capData[areaName].core}</p>
                                </span>
                                <span className="core-perc">
                                    {
                                        indicator === 'Leading' ?
                                            <>
                                                <p className="title">LEADING</p>
                                                <p className="value">
                                                    <span className="indicator-icon icon-leading">
                                                        <Leading />
                                                    </span>
                                                    <span>{capData[areaName].core_perc}%</span>
                                                </p>
                                            </> :
                                        indicator === 'Basic' ?
                                            <>
                                                <p className="title">BASIC</p>
                                                <p className="value">
                                                    <span className="indicator-icon icon-basic">
                                                        <Basic />
                                                    </span>
                                                    <span>{capData[areaName].core_perc}%</span>
                                                </p>
                                            </> :
                                            <>
                                                <p className="title">LAGGING</p>
                                                <p className="value">
                                                    <span className="indicator-icon icon-lagging">
                                                        <Lagging />
                                                    </span>
                                                    <span>{capData[areaName].core_perc}%</span>
                                                </p>
                                            </>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CapabilityArea;