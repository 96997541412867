function ProgressBar({ className, completed }: { className: string, completed: number }) {

  const fillerStyles = {
    flex: `${completed / 100}`
  }

  return (
    <div className={className}>
      <div className="progress-bar-container">
        <div className="progress-bar-value">
          <div className="progress-bar-perc" style={fillerStyles}>
            {`${Math.round(completed)}`}
            <span className="uom">%</span>
          </div>
        </div>
        <div className='progress-bar'>
          <div className="progress-bar-track"></div>
          <div className='progress-bar-range' style={fillerStyles}></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;