function IndicatorInfo({ indicatorName, indicatorValue }: { indicatorName: string, indicatorValue: number }) {

    return (
        <div className='indicator-info'>
            <div className="indicator-name">{indicatorName}</div>
            <div className="indicator-value">{indicatorValue}</div>
        </div>
    );
};

export default IndicatorInfo;