import { MouseEventHandler } from "react";

function DriverParallelogram({ driverName, onClick, icon, className }: { driverName: string, onClick: MouseEventHandler, icon: JSX.Element, className: string }) {

    return (
        <div className={className} onClick={onClick}>
            <div className="parallelogram-content">
                <div className="parallelogram-icon">
                    {icon}
                </div>
                <div className='parallelogram-title'>
                    {driverName}
                </div>
            </div>
        </div>
    );
};

export default DriverParallelogram;