function DetailIcon({ iconTitle, icon }: { iconTitle: string, icon: JSX.Element }) {

    return (
        <div className='icon-box'>
            {icon}
            <div className='icon-title'>
                <div>{iconTitle}</div>
            </div>
        </div>
    );
};

export default DetailIcon;