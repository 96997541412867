import { useEffect, useState } from 'react';
import { readRemoteFile } from 'react-papaparse';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Europe } from '../assets/europe-map.svg';
import { ReactComponent as EuropeSM } from '../assets/europe.svg';
import { ReactComponent as ItalySM } from '../assets/italy.svg';
import { ReactComponent as SpainSM } from '../assets/spain.svg';
import { ReactComponent as Systems } from '../assets/icons/systems.svg';
import { ReactComponent as TopProjects } from '../assets/icons/initiative.svg';
import { ReactComponent as People } from '../assets/icons/people.svg';
import { ReactComponent as Budget } from '../assets/icons/budget.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Lock } from '../assets/icons/lock.svg';
// import { ReactComponent as Logo } from '../assets/logo-mfe.svg';
import { ReactComponent as Atom } from '../assets/icons/atom.svg';
import CountryInfo from '../components/CountryInfo';
import { playClick } from '../utilities/sounds';
import '../App.css';

function EuropeMap() {
    const navigate = useNavigate();

    const it = document.getElementById('it')!;
    const es = document.getElementById('es')!;

    const italy = document.getElementById('italy')!;
    italy?.addEventListener('click', () => {
        selectCountry('Italy');
        playClick();
    });

    const spain = document.getElementById('spain')!;
    spain?.addEventListener('click', () => {
        selectCountry('Spain');
        playClick();
    });

    const [country, setCountry] = useState('Europe');
    const selectCountry = (country: string) => {
        setCountry(country);
        switch (country) {
            case 'Italy':
                italy.classList.add("active");
                it.classList.add("selected");
                spain.classList.remove('active');
                es.classList.remove('selected');
                break;
            case 'Spain':
                spain.classList.add("active");
                es.classList.add("selected");
                italy.classList.remove('active');
                it.classList.remove('selected');
                break;
            default:
                spain.classList.remove("active");
                es.classList.remove('selected');
                italy.classList.remove('active');
                it.classList.remove('selected');
                break;
        }
    }

    const [dataCountry, setDataCountry] = useState<any>({});

    useEffect(() => {
        const btnApp = document.getElementById('btn-systems')! as HTMLButtonElement;
        if (btnApp !== null) {
            btnApp!.disabled = country === 'Italy' ? false : true;
        }

        readRemoteFile('../lista_country.csv', {
            delimiter: ',',
            header: true,
            skipEmptyLines: true,
            download: true,
            complete: (result: any) => {
                // lista completa, risultato del csv parsato
                let res = result.data;
                let dataSpalla: any;

                // se è stata cliccata una country, filtro la lista per la country corrispondente
                // altrimenti uso la lista completa
                // e costruisco i dati da mostrare nella spalla laterale
                if (country !== 'Europe') {
                    res = res.filter((row: any) => row.Country === country)[0];
                    dataSpalla = {
                        name: res.Country,
                        systems: parseInt(res.Systems),
                        initiative: parseInt(res.Top_Projects),
                        people: parseInt(res.People),
                        budget: parseInt(res.Budget)
                    };
                } else {
                    dataSpalla = {
                        name: 'Europe',
                        systems: calculateSum(res, 'Systems'),
                        initiative: calculateSum(res, 'Top_Projects'),
                        people: calculateSum(res, 'People'),
                        budget: calculateSum(res, 'Budget')
                    };
                }
                setDataCountry(dataSpalla);
            },
        });
    }, [country])

    function calculateSum(array: [], property: string) {
        return array.reduce((accumulator: number, row: any) => {
            return accumulator + parseInt(row[property]);
        }, 0);
    }

    return (
        <div className='wrapper home'>
            <div className='header'>
                <div className="logo-border">
                    <div className='mds-logo' onClick={() => navigate('/home')}>
                        <Atom />
                    </div>
                </div>
                <div className='navbar'>
                    <div className='nav'>
                        <div className="nav-title">Capability Dashboard</div>
                    </div>
                    <div className='driver-filter'></div>
                </div>
            </div>
            <div className='content'>
                <div className='sider sider-left'>
                    <div className="sider-border">
                        <div className='sider-box'>
                            <div className='sider-header-border'>
                                <div className='sider-header'>
                                    <div className='sider-name'>{dataCountry.name}</div>
                                </div>
                            </div>
                            <div className="sider-content">
                                {country !== 'Europe' &&
                                    <div className='sider-close-btn'>
                                        <Close onClick={() => { selectCountry('Europe'); playClick(); }} />
                                    </div>
                                }
                                <div className='map-sm'>
                                    {country === 'Italy' ? <ItalySM /> : country === 'Spain' ? <SpainSM /> : <EuropeSM />}
                                </div>
                                <div className="info-rows">
                                    <div className='info-row'>
                                        <CountryInfo infoTitle={'Systems'} infoValue={dataCountry.systems} icon={<Systems />} uom={''} />
                                        <CountryInfo infoTitle={'Top Projects'} infoValue={dataCountry.initiative} icon={<TopProjects />} uom={''} />
                                    </div>
                                    <div className='info-row row-reverse'>
                                        <CountryInfo infoTitle={'People'} infoValue={dataCountry.people} icon={<People />} uom={''} />
                                        <CountryInfo infoTitle={'Budget'} infoValue={dataCountry.budget} icon={<Budget />} uom={'M€'} />
                                    </div>
                                </div>
                                <div className='sider-footer'>
                                    {country === 'Europe' ?
                                        <div className='footer-hint'>Select a Country</div> :
                                        <>
                                            <div className='footer-btn'>
                                                <button className='btn' id='btn-systems' onClick={() => { navigate('/it-application'); playClick(); }}>
                                                    <Lock style={{ display: country === 'Italy' ? 'none' : 'block' }} />
                                                    Systems
                                                </button>
                                            </div>
                                            <div className='footer-btn'>
                                                <button className='btn' disabled>
                                                    <Lock />
                                                    Infrastructure</button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='map-lg'>
                    <Europe />
                </div>
            </div>
        </div>
    );
}

export default EuropeMap;
