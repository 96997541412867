import ProgressBar from '../components/ProgressBar';
import CapabilityArea from '../components/CapabilityArea';
import CapexOpex from '../components/CapexOpex';
import TotalCore from '../components/TotalCore';
import IndicatorInfo from '../components/IndicatorInfo';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { playClick } from '../utilities/sounds';
import '../App.css';

function CapabilityMap({ fromCapMap_area, fromItApp_siderData, fromCapMap_showDetail, fromItApp_capabilityData, fromItApp_selectedArea, fromItApp_capMapClass, fromItApp_indicator, fromItApp_contentClass }: { fromCapMap_area: (area: string) => void, fromItApp_siderData: any, fromCapMap_showDetail: () => void, fromItApp_capabilityData: any, fromItApp_selectedArea: string, fromItApp_capMapClass: string, fromItApp_indicator: string, fromItApp_contentClass: string }) {
    const selectArea = (area: string) => {
        playClick();
        fromCapMap_area(area);
    }

    function setActive(selectedArea: string, area: string, cssClass: string) {
        return selectedArea === area ? cssClass + ' active' : cssClass;
    }

    return (
        <>
            <div id='content-to-hide-1' className={fromItApp_contentClass}>
                <div className={fromItApp_capMapClass}>
                    <CapabilityArea className={'capability capability-hor-lg'}
                        activeStyle={setActive(fromItApp_selectedArea, 'Strategy & Vision', 'capability-content capability-content-hor-lg')} indicator={fromItApp_indicator}
                        onClick={() => selectArea('Strategy & Vision')} areaName={'Strategy & Vision'} capData={fromItApp_capabilityData} />
                    <div className='cap-center'>
                        <CapabilityArea className={'capability capability-vert'}
                            activeStyle={setActive(fromItApp_selectedArea, 'Consumer Strategy & Engagement', 'capability-content capability-content-vert')} indicator={fromItApp_indicator}
                            onClick={() => selectArea('Consumer Strategy & Engagement')} areaName={'Consumer Strategy & Engagement'} capData={fromItApp_capabilityData} />
                        <CapabilityArea className={'capability capability-vert'}
                            activeStyle={setActive(fromItApp_selectedArea, 'Direct Marketing', 'capability-content capability-content-vert')} indicator={fromItApp_indicator}
                            onClick={() => selectArea('Direct Marketing')} areaName={'Direct Marketing'} capData={fromItApp_capabilityData} />
                        <div className='cap-med-center'>
                            <CapabilityArea className={'capability capability-hor-md'}
                                activeStyle={setActive(fromItApp_selectedArea, 'Advertising', 'capability-content capability-content-hor-md')} indicator={fromItApp_indicator}
                                onClick={() => selectArea('Advertising')} areaName={'Advertising'} capData={fromItApp_capabilityData} />
                            <div className='cap-super-center'>
                                <CapabilityArea className={'capability capability-hor-sm'}
                                    activeStyle={setActive(fromItApp_selectedArea, 'Programming & Planning', 'capability-content capability-content-hor-sm')} indicator={fromItApp_indicator}
                                    onClick={() => selectArea('Programming & Planning')} areaName={'Programming & Planning'} capData={fromItApp_capabilityData} />
                                <CapabilityArea className={'capability capability-hor-sm'}
                                    activeStyle={setActive(fromItApp_selectedArea, 'Rights Management', 'capability-content capability-content-hor-sm')} indicator={fromItApp_indicator}
                                    onClick={() => selectArea('Rights Management')} areaName={'Rights Management'} capData={fromItApp_capabilityData} />
                            </div>
                            <CapabilityArea className={'capability capability-hor-md'}
                                activeStyle={setActive(fromItApp_selectedArea, 'Production', 'capability-content capability-content-hor-md')} indicator={fromItApp_indicator}
                                onClick={() => selectArea('Production')} areaName={'Production'} capData={fromItApp_capabilityData} />
                            <CapabilityArea className={'capability capability-hor-md'}
                                activeStyle={setActive(fromItApp_selectedArea, 'Supply chain', 'capability-content capability-content-hor-md')} indicator={fromItApp_indicator}
                                onClick={() => selectArea('Supply chain')} areaName={'Supply chain'} capData={fromItApp_capabilityData} />
                        </div>
                        <CapabilityArea className={'capability capability-vert'}
                            activeStyle={setActive(fromItApp_selectedArea, 'Delivery & Distribution', 'capability-content capability-content-vert')} indicator={fromItApp_indicator}
                            onClick={() => selectArea('Delivery & Distribution')} areaName={'Delivery & Distribution'} capData={fromItApp_capabilityData} />
                    </div>
                    <CapabilityArea className={'capability capability-hor-lg'}
                        activeStyle={setActive(fromItApp_selectedArea, 'Analytics', 'capability-content capability-content-hor-lg')} indicator={fromItApp_indicator}
                        onClick={() => selectArea('Analytics')} areaName={'Analytics'} capData={fromItApp_capabilityData} />
                    <CapabilityArea className={'capability capability-hor-lg'}
                        activeStyle={setActive(fromItApp_selectedArea, 'Enterprise Management', 'capability-content capability-content-hor-lg')} indicator={fromItApp_indicator}
                        onClick={() => selectArea('Enterprise Management')} areaName={'Enterprise Management'} capData={fromItApp_capabilityData} />
                </div>
                <div className='sider sider-right'>
                    <div className="sider-border">
                        <div className='sider-box'>
                            <div className='sider-header-border'>
                                <div className='sider-header'>
                                    <div className='sider-name'>{fromItApp_siderData.name}</div>
                                </div>
                            </div>
                            <div className='sider-content'>
                                {fromItApp_selectedArea !== 'Overview' &&
                                    <div className='sider-close-btn'>
                                        <Close onClick={() => { selectArea('Overview'); playClick(); }} />
                                    </div>
                                }
                                <div className='sider-section'>
                                    <div className='section-title'>Working Capital</div>
                                </div>
                                <div className='capex-opex-section'>
                                    <CapexOpex itemName={'Capex'} itemValue={fromItApp_siderData.capex} />
                                    <CapexOpex itemName={'Opex'} itemValue={fromItApp_siderData.opex} />
                                </div>
                                <div className='sider-section'>
                                    <div className='section-title'>Systems</div>
                                </div>
                                <div className='systems'>
                                    <div className='systems-column'>
                                        <TotalCore itemName={'Total'} itemValue={fromItApp_siderData.total} />
                                        <div className="driver-indicators">
                                            <div className='indicator-box'>
                                                <IndicatorInfo indicatorName={'Leading'} indicatorValue={fromItApp_siderData.total_leading} />
                                                <ProgressBar className={'bar-leading'} completed={fromItApp_siderData.total_leading_perc} />
                                            </div>
                                            <div className='indicator-box'>
                                                <IndicatorInfo indicatorName={'Basic'} indicatorValue={fromItApp_siderData.total_basic} />
                                                <ProgressBar className={'bar-basic'} completed={fromItApp_siderData.total_basic_perc} />
                                            </div>
                                            <div className='indicator-box'>
                                                <IndicatorInfo indicatorName={'Lagging'} indicatorValue={fromItApp_siderData.total_lagging} />
                                                <ProgressBar className={'bar-lagging'} completed={fromItApp_siderData.total_lagging_perc} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='systems-column'>
                                        <TotalCore itemName={'Core'} itemValue={fromItApp_siderData.core} />
                                        <div className="driver-indicators">
                                            <div className='indicator-box'>
                                                <IndicatorInfo indicatorName={'Leading'} indicatorValue={fromItApp_siderData.core_leading} />
                                                <ProgressBar className={'bar-leading'} completed={fromItApp_siderData.core_leading_perc} />
                                            </div>
                                            <div className='indicator-box'>
                                                <IndicatorInfo indicatorName={'Basic'} indicatorValue={fromItApp_siderData.core_basic} />
                                                <ProgressBar className={'bar-basic'} completed={fromItApp_siderData.core_basic_perc} />
                                            </div>
                                            <div className='indicator-box'>
                                                <IndicatorInfo indicatorName={'Lagging'} indicatorValue={fromItApp_siderData.core_lagging} />
                                                <ProgressBar className={'bar-lagging'} completed={fromItApp_siderData.core_lagging_perc} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sider-footer'>
                                    {fromItApp_selectedArea === 'Overview' ?
                                        <div className='footer-hint'>Select an Area</div> :
                                        <div className='footer-btn'>
                                            <button className='btn' onClick={() => fromCapMap_showDetail()}>Open Detail</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CapabilityMap;