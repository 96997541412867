

function CountryData({ infoTitle, infoValue, icon, uom }: { infoTitle: string, infoValue: number, icon: JSX.Element, uom: string }) {

    return (
        <div className='info-content'>
            <div className="info-icon-border">
                <div className='info-icon'>
                    {icon}
                </div>
            </div>
            <div className='info-box'>
                <div className='info-title'>
                    <div>{infoTitle}</div>
                </div>
                <div className='info-value'>
                    <div>{infoValue}</div>
                    <span className="uom">{uom}</span>
                </div>
            </div>
        </div>
    );
};

export default CountryData;