import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import EuropeMap from './pages/EuropeMap';
import ItApplication from './pages/ItApplication';
import './App.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<EuropeMap />} />
          <Route path="/it-application" element={<ItApplication />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
