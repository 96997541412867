const click = new Audio("../click.mp3");
const type = new Audio("../type.webm");

function playClick() {
    click.play();
}

function playType() {
    type.play();
}

export { playClick, playType };