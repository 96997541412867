import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Atom } from '../assets/icons/atom.svg';
import { playClick } from "../utilities/sounds";
import '../App.css';

function Login() {
    const navigate = useNavigate();
    const inputRef = useRef(null);

    const [showMsg, setShowMsg] = useState(false);

    function handleLogin() {
        playClick();
        let input: any = inputRef.current!;
        if (input.value === 'symphonie') {
            navigate('/home');
        } else {
            setShowMsg(true);
        }
    }

    return (
        <div className='wrapper login'>
            <div className='login-content'>
                <div className="login-img">
                    <Atom />
                </div>
                <form className="login-form">
                    <div className="login-input">
                        <p>Enter password</p>
                        <input ref={inputRef} type="password" placeholder="Password" />
                        {showMsg && <p className="login-error-msg">Wrong password!</p>}
                    </div>
                    <div className='login-btn'>
                        <button type="submit" className='btn' onClick={handleLogin}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;