import { useEffect, useRef, useState } from 'react';
import VanillaTilt from 'vanilla-tilt';
import ProgressBar from '../components/ProgressBar';
import TotalCore from '../components/TotalCore';
import IndicatorInfo from '../components/IndicatorInfo';
import DetailInfo from '../components/DetailInfo';
import DetailIcon from '../components/DetailIcon';
import { ReactComponent as Leading } from '../assets/icons/leading.svg';
import { ReactComponent as Basic } from '../assets/icons/basic.svg';
import { ReactComponent as Lagging } from '../assets/icons/lagging.svg';
import { ReactComponent as Core } from '../assets/icons/core.svg';
import { ReactComponent as NoCoreLow } from '../assets/icons/no-core-low.svg';
import { ReactComponent as NoCoreMedium } from '../assets/icons/no-core-medium.svg';
import { ReactComponent as Cloud } from '../assets/icons/cloud.svg';
import { ReactComponent as Prem } from '../assets/icons/prem.svg';
import { ReactComponent as NoData } from '../assets/icons/no-data.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as BannerTotal } from '../assets/icons/banner-total.svg';
import { ReactComponent as BannerCore } from '../assets/icons/banner-core.svg';
import { ReactComponent as BannerLeading } from '../assets/icons/banner-leading.svg';
import { ReactComponent as BannerBasic } from '../assets/icons/banner-basic.svg';
import { ReactComponent as BannerLagging } from '../assets/icons/banner-lagging.svg';
import { playClick } from '../utilities/sounds';
import '../App.css';

const styles = {
    colorLeading: {
        backgroundColor: '#00E9A5'
    },
    colorBasic: {
        backgroundColor: '#FBE829'
    },
    colorLagging: {
        backgroundColor: '#FF8C84'
    },
    colorDefault: {
        backgroundColor: '#18859A'
    }
};

function Detail({ fromItApp_siderData, fromItApp_dataCore, fromItApp_dataNoCore, fromItApp_indicator, fromItApp_unsetIndicator, fromItApp_contentClass, fromItApp_bannerClass }: { fromItApp_siderData: any, fromItApp_dataCore: any, fromItApp_dataNoCore: any, fromItApp_indicator: string, fromItApp_unsetIndicator: boolean, fromItApp_contentClass: string, fromItApp_bannerClass: string }) {
    const [isShown, setIsShown] = useState(false);

    const [dataCore, setDataCore] = useState([]);
    const [dataNoCore, setDataNoCore] = useState([]);

    const [hexCoreLength, setHexCoreLength] = useState(false);
    const [hexNoCoreLength, setHexNoCoreLength] = useState(false);

    const [app, setApp] = useState<any>(undefined);

    const hexRef: any = useRef({}).current;
    const saveHexRef = (key: any) => (ref: any) => {
        hexRef[key] = ref;
        VanillaTilt.init(hexRef[key], { scale: 1.1 });
    }

    const boxRef: any = useRef({}).current;
    const saveBoxRef = (key: any) => (ref: any) => {
        boxRef[key] = ref;
        VanillaTilt.init(boxRef[key]);
    }

    useEffect(() => {
        let filteredDataCore = fromItApp_unsetIndicator ? fromItApp_dataCore : fromItApp_dataCore.filter((row: any) => row.driver === fromItApp_indicator);
        let filteredDataNoCore = fromItApp_unsetIndicator ? fromItApp_dataNoCore : fromItApp_dataNoCore.filter((row: any) => row.driver === fromItApp_indicator);
        setDataCore(filteredDataCore);
        setDataNoCore(filteredDataNoCore);
        setHexCoreLength(filteredDataCore.length > 0);
        setHexNoCoreLength(filteredDataNoCore.length > 0);
        if (app !== undefined) {
            const data = [...filteredDataCore, ...filteredDataNoCore];
            const found = data.find((d: any) => d.cmdb === app.cmdb);
            if (found === undefined) {
                setIsShown(false);
                setApp(undefined);
            } else {
                setApp(found);
            }
        }
    }, [fromItApp_dataCore, fromItApp_dataNoCore, app, fromItApp_indicator, fromItApp_unsetIndicator])

    const hexDataCore = mapHexData(dataCore, 'hex-border core');
    const hexDataNoCore = mapHexData(dataNoCore, 'hex-border nocore');

    function mapHexData(array: Array<any>, cssClass: string) {
        return array.map((data: any, id: number) => {
            let borderColor = data.driver === 'Leading' ? styles.colorLeading : data.driver === 'Basic' ? styles.colorBasic : data.driver === 'Lagging' ? styles.colorLagging : styles.colorDefault;
            return <div ref={saveHexRef(id)} key={id} className={cssClass} style={borderColor} onClick={() => { setApp(data); setIsShown(true); playClick(); }}>
                <div className={setActive(app, data.name, 'hex-text')}>
                    <p className='hex-cmdb'>{data.cmdb}</p>
                    <p className='hex-title'>{data.name}</p>
                    <p className='hex-opex'>{(data.opex / 1000).toLocaleString('it-IT', { maximumFractionDigits: 1 })} <span className="uom">K€</span></p>
                </div>
            </div>
        })
    }

    const initiativeData = app !== undefined ? mapInitiativeData(app.ongoingInitiatives) : [];

    function mapInitiativeData(array: Array<any>) {
        return array.map((data: any, id: number) => {
            return <div ref={saveBoxRef(id)} key={id} className='initiative-box'>
                <div className='initiative-content'>
                    <p className='initiative-title'>{data.nome}</p>
                    <div className='initiative-row'>
                        <p className='initiative-date'>{data.data_inizio} - {data.data_fine}</p>
                    </div>
                    <div className='initiative-row'>
                        <DetailInfo detailTitle={'Capex'} detailValue={(data.capex / 1000).toLocaleString('it-IT', { maximumFractionDigits: 1 })} uom={'K€'} />
                        <DetailInfo detailTitle={'Opex'} detailValue={(data.opex / 1000).toLocaleString('it-IT', { maximumFractionDigits: 1 })} uom={'K€'} />
                    </div>
                </div>
            </div>
        })
    }

    function setActive(selectedApp: any, appName: string, cssClass: string) {
        return selectedApp === undefined ? cssClass : selectedApp.name === appName ? cssClass + ' hex-active' : cssClass;
    }

    return (
        <>
            <div id='content-to-hide-2' className={fromItApp_bannerClass}>
                <div className='banner-box'>
                    <div className='banner-row'>
                        <div className='banner-info-box'>
                            <div className='banner-icon'><BannerTotal /></div>
                            <TotalCore itemName={'Total'} itemValue={fromItApp_siderData.total} />
                        </div>
                        <div className='indicator-box'>
                            <div className='banner-indicator'>
                                <div className='banner-icon'><BannerLeading /></div>
                                <IndicatorInfo indicatorName={'Leading'} indicatorValue={fromItApp_siderData.total_leading} />
                            </div>
                            <ProgressBar className={'progress-bar bar-leading'} completed={fromItApp_siderData.total_leading_perc} />
                        </div>
                        <div className='indicator-box'>
                            <div className='banner-indicator'>
                                <div className='banner-icon'><BannerBasic /></div>
                                <IndicatorInfo indicatorName={'Basic'} indicatorValue={fromItApp_siderData.total_basic} />
                            </div>
                            <ProgressBar className={'progress-bar bar-basic'} completed={fromItApp_siderData.total_basic_perc} />
                        </div>
                        <div className='indicator-box'>
                            <div className='banner-indicator'>
                                <div className='banner-icon'><BannerLagging /></div>
                                <IndicatorInfo indicatorName={'Lagging'} indicatorValue={fromItApp_siderData.total_lagging} />
                            </div>
                            <ProgressBar className={'progress-bar bar-lagging'} completed={fromItApp_siderData.total_lagging_perc} />
                        </div>
                    </div>
                    <div className='banner-row'>
                        <div className='banner-info-box'>
                        <div className='banner-icon'><BannerCore /></div>
                            <TotalCore itemName={'Core'} itemValue={fromItApp_siderData.core} />
                        </div>
                        <div className='indicator-box'>
                            <div className='banner-indicator'>
                                <div className='banner-icon'><BannerLeading /></div>
                                <IndicatorInfo indicatorName={'Leading'} indicatorValue={fromItApp_siderData.core_leading} />
                            </div>
                            <ProgressBar className={'progress-bar bar-leading'} completed={fromItApp_siderData.core_leading_perc} />
                        </div>
                        <div className='indicator-box'>
                            <div className='banner-indicator'>
                                <div className='banner-icon'><BannerBasic /></div>
                                <IndicatorInfo indicatorName={'Basic'} indicatorValue={fromItApp_siderData.core_basic} />
                            </div>
                            <ProgressBar className={'progress-bar bar-basic'} completed={fromItApp_siderData.core_basic_perc} />
                        </div>
                        <div className='indicator-box'>
                            <div className='banner-indicator'>
                                <div className='banner-icon'><BannerLagging /></div>
                                <IndicatorInfo indicatorName={'Lagging'} indicatorValue={fromItApp_siderData.core_lagging} />
                            </div>
                            <ProgressBar className={'progress-bar bar-lagging'} completed={fromItApp_siderData.core_lagging_perc} />
                        </div>
                    </div>
                </div>
            </div>
            <div id='content-to-hide-3' className={fromItApp_contentClass}>
                <div className='detail-container'>
                    <div className='hex-container'>
                        <div className="hex-core-container">
                            <div className='hex-vert-text core'>Core</div>
                            {hexCoreLength ?
                                <>
                                    <div className='hex-box'>
                                        <div className='hex-content core'>
                                            {hexDataCore}
                                        </div>
                                    </div>
                                </> :
                                <div className='hex-no-data'>No data</div>
                            }
                        </div>
                        {/* <div className="hex-separator"></div> */}
                        <div className="hex-nocore-container">
                            <div className='hex-vert-text nocore'>Non Core</div>
                            {hexNoCoreLength ?
                                <>
                                    <div className='hex-box'>
                                        <div className='hex-content nocore'>
                                            {hexDataNoCore}
                                        </div>
                                    </div>
                                </> :
                                <div className='hex-no-data'>No data</div>
                            }
                        </div>
                    </div>
                </div>
                {isShown &&
                    <div className='sider-detail'>
                        <div className='sider sider-right'>
                            <div className="sider-border">
                                <div className='sider-box'>
                                    <div className='sider-header-border'>
                                        <div className='sider-header'>
                                            <div className='sider-name'>{app.name}</div>
                                        </div>
                                    </div>
                                    <div className='sider-close-btn'>
                                        <Close onClick={() => { setIsShown(false); playClick(); }} />
                                    </div>
                                    <div className='sider-content'>
                                        <div className='detail-icons'>
                                            {app.driver === 'Leading' ? <DetailIcon iconTitle={app.driver} icon={<Leading fill='#00E9A5' />} /> :
                                            app.driver === 'Basic' ? <DetailIcon iconTitle={app.driver} icon={<Basic fill='#FBE829' />} /> :
                                            app.driver === 'Lagging' ? <DetailIcon iconTitle={app.driver} icon={<Lagging fill='#FF8C84' />} /> :
                                            <DetailIcon iconTitle={'No Data'} icon={<NoData fill='#18859A' />} />}

                                            {app.importance === 'Core' ? <DetailIcon iconTitle={app.importance} icon={<Core fill='#18859A' />} /> :
                                            app.importance === 'Low' ? <DetailIcon iconTitle={app.importance} icon={<NoCoreLow fill='#18859A' />} /> :
                                            app.importance === 'Medium' ? <DetailIcon iconTitle={app.importance} icon={<NoCoreMedium fill='#18859A' />} /> :
                                            <DetailIcon iconTitle={'No Data'} icon={<NoData fill='#18859A' />} />}

                                            {app.location === 'On Cloud' ? <DetailIcon iconTitle={app.location} icon={<Cloud fill='#18859A' />} /> :
                                            app.location === 'On Premise' ? <DetailIcon iconTitle={app.location} icon={<Prem fill='#18859A' />} /> :
                                            <DetailIcon iconTitle={'No Data'} icon={<NoData fill='#18859A' />} />}
                                        </div>
                                        <div className='detail-infos'>
                                            <div className='sider-section no-space'>
                                                <div className='section-title'>Description</div>
                                            </div>
                                            <div className='detail-description'>{app.description}</div>
                                            <div className='sider-section'>
                                                <div className='section-title'>Information</div>
                                            </div>
                                            <DetailInfo detailTitle={'Opex'} detailValue={(app.opex / 1000).toLocaleString('it-IT', { maximumFractionDigits: 1 })} uom={'K€'} />
                                            <DetailInfo detailTitle={'Owner'} detailValue={app.owner} uom={''} />
                                            <DetailInfo detailTitle={'Responsible'} detailValue={app.responsible} uom={''} />
                                            <DetailInfo detailTitle={'DR Available'} detailValue={app.drAvailable} uom={''} />
                                            <div className='sider-section'>
                                                <div className='section-title'>Initiatives</div>
                                            </div>
                                            {app.ongoingInitiatives.length > 0 ? initiativeData :
                                            <p className='detail-title'>No ongoing initiatives</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default Detail;