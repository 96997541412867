function DetailInfo({ detailTitle, detailValue, uom }: { detailTitle: string, detailValue: string, uom: string }) {

    return (
        <div className='detail-info'>
            <div className="detail-title">{detailTitle}</div>
            <div className="detail-value">{detailValue}
                <span className="uom">{uom}</span>
            </div>
        </div>
    );
};

export default DetailInfo;