function TotalCore({ itemName, itemValue }: { itemName: string, itemValue: number }) {

    return (
        <div className='total-core-box'>
            <div className="total-core-title">{itemName}</div>
            <div className="total-core-value">{itemValue}</div>
        </div>
    );
};

export default TotalCore;