import { useEffect, useState } from 'react';
import { readRemoteFile } from 'react-papaparse';
import { useNavigate } from 'react-router-dom';
import CapabilityMap from './CapabilityMap';
import Detail from './Detail';
import DriverParallelogram from '../components/DriverParallelogram';
import { ReactComponent as Globe } from '../assets/icons/globe.svg';
import { ReactComponent as Overall } from '../assets/icons/overall.svg';
import { ReactComponent as Obsolescence } from '../assets/icons/obsolescence.svg';
import { ReactComponent as Coverage } from '../assets/icons/coverage.svg';
import { ReactComponent as Evolvability } from '../assets/icons/evolvability.svg';
// import { ReactComponent as Logo } from '../assets/logo-mfe.svg';
import { ReactComponent as Atom } from '../assets/icons/atom.svg';
import { ReactComponent as Leading } from '../assets/icons/leading.svg';
import { ReactComponent as Basic } from '../assets/icons/basic.svg';
import { ReactComponent as Lagging } from '../assets/icons/lagging.svg';
import { ReactComponent as All } from '../assets/icons/all.svg';
import { playClick } from '../utilities/sounds';
import '../App.css';
import SubmenuItem from '../components/SubmenuItem';

function ItApplication() {
    const navigate = useNavigate();

    const [showCapMap, setShowCapMap] = useState(true);
    const [submenuStyle, setSubmenuStyle] = useState({});
    const [capMapClass, setCapMapClass] = useState('cap-map');
    const [contentClass, setContentClass] = useState('content');
    const [bannerClass, setBannerClass] = useState('detail-banner');
    const [currentTarget, setCurrentTarget] = useState('');
    const [tempDriver, setTempDriver] = useState('overall');
    const [driver, setDriver] = useState('overall');
    const [area, setArea] = useState('Overview');
    const [indicator, setIndicator] = useState('Leading');
    const [indicatorFlag, unsetIndicator] = useState(false);
    const [siderData, setSiderData] = useState({});
    const [capabilityData, setCapabilityData] = useState({});
    const [dataCore, setDataCore] = useState<Array<any>>([]);
    const [dataNoCore, setDataNoCore] = useState<Array<any>>([]);

    const backToCapMap = () => {
        playClick();
        setShowCapMap(true);
        setDriver('overall');
        setIndicator('Leading');
        setCapMapClass('cap-map');
        unsetIndicator(false);
        hideSubmenu();
    }

    const showSubmenu = (driver: string, e: any) => {
        setCurrentTarget(e.currentTarget);
        const capMapContentHidden = document.getElementById('content-to-hide-1')?.className.includes('hide');
        const detailBannerHidden = document.getElementById('content-to-hide-2')?.className.includes('hide');
        const detailContentHidden = document.getElementById('content-to-hide-3')?.className.includes('hide');
        playClick();
        setTempDriver(driver);
        if ((capMapContentHidden || detailBannerHidden || detailContentHidden) && currentTarget === e.currentTarget) {
            hideSubmenu();
        } else {
            setContentClass('content hide');
            setBannerClass('detail-banner hide');
            setSubmenuStyle({ display: 'flex', left: (e.currentTarget.offsetLeft + 3) + 'px', width: (e.currentTarget.clientWidth - 57) + 'px' });
        }
    }

    const hideSubmenu = () => {
        setContentClass('content');
        setBannerClass('detail-banner');
        setSubmenuStyle({ display: 'none' });
    }

    const selectIndicator = (indicator: string, indicatorFlag: boolean) => {
        playClick();
        if (indicator !== 'All') {
            setIndicator(indicator);
        }
        unsetIndicator(indicatorFlag);
        setDriver(tempDriver);
        setCapMapClass(tempDriver !== 'overall' ? 'cap-map ' + tempDriver : 'cap-map');
        hideSubmenu();
    }

    const fromCapMap_area = (area: string) => {
        setArea(area);
    }

    const fromCapMap_showDetail = () => {
        playClick();
        setShowCapMap(false);
        unsetIndicator(true);
        hideSubmenu();
    }

    useEffect(() => {
        window.addEventListener('resize', () => { hideSubmenu(); });

        readRemoteFile('../lista_sistemi.csv', {
            delimiter: ',',
            header: true,
            skipEmptyLines: true,
            download: true,
            complete: (result: any) => {
                // lista completa, risultato del csv parsato
                let res = result.data;

                // se è stata cliccata un'area, filtro la lista per l'area corrispondente
                if (area !== 'Overview') {
                    res = res.filter((row: any) => row.Level_1 === area);
                }

                // divido la lista completa tra sistemi core/no core
                let core = res.filter((row: any) => row.Importance === 'Core');
                let noCore = res.filter((row: any) => row.Importance !== 'Core');

                // lista di iniziative per i soli sistemi core
                const coreInitiatives: Array<any> = [];
                core.forEach((row: any) => {
                    if (row.Iniziative_Ongoing !== '' && row.Iniziative_Ongoing !== ' ') {
                        // if(row.Iniziative_Ongoing.length > 0) {
                        coreInitiatives.push(...JSON.parse(row.Iniziative_Ongoing))
                    }
                });

                // leading, basic, lagging in base al driver selezionato
                let totalDrivers: Array<number> = [];
                let coreDrivers: Array<number> = [];
                switch (driver) {
                    case 'overall':
                        totalDrivers = calculateDriverNum(res, 'Driver_Overall');
                        coreDrivers = calculateDriverNum(core, 'Driver_Overall');
                        break;
                    case 'obsolescence':
                        totalDrivers = calculateDriverNum(res, 'Driver_Obsolescence');
                        coreDrivers = calculateDriverNum(core, 'Driver_Obsolescence');
                        break;
                    case 'coverage':
                        totalDrivers = calculateDriverNum(res, 'Driver_Coverage');
                        coreDrivers = calculateDriverNum(core, 'Driver_Coverage');
                        break;
                    case 'evolvability':
                        totalDrivers = calculateDriverNum(res, 'Driver_Evolvability');
                        coreDrivers = calculateDriverNum(core, 'Driver_Evolvability');
                        break;
                }

                // costruisco i dati da mostrare nella spalla laterale
                let dataSpalla: any = {
                    name: area,
                    capex: calculateSum(core, 'CAPEX_Sistema') + calculateSum(coreInitiatives, 'capex'),
                    opex: calculateSum(core, 'OPEX_Sistema') + calculateSum(coreInitiatives, 'opex'),
                    total: res.length,
                    total_leading: totalDrivers[0],
                    total_leading_perc: calculatePercentage(totalDrivers[0], res.length),
                    total_basic: totalDrivers[1],
                    total_basic_perc: calculatePercentage(totalDrivers[1], res.length),
                    total_lagging: totalDrivers[2],
                    total_lagging_perc: calculatePercentage(totalDrivers[2], res.length),
                    core: core.length,
                    core_leading: coreDrivers[0],
                    core_leading_perc: calculatePercentage(coreDrivers[0], core.length),
                    core_basic: coreDrivers[1],
                    core_basic_perc: calculatePercentage(coreDrivers[1], core.length),
                    core_lagging: coreDrivers[2],
                    core_lagging_perc: calculatePercentage(coreDrivers[2], core.length)
                };
                setSiderData(dataSpalla);

                let dataCapMap: any = {};
                // lista completa delle aree cliccabili in capability map
                const areas = ['Strategy & Vision', 'Consumer Strategy & Engagement', 'Direct Marketing', 'Advertising', 'Programming & Planning',
                    'Rights Management', 'Production', 'Supply chain', 'Delivery & Distribution', 'Analytics', 'Enterprise Management'];

                // ragguppo la lista risultato del csv parsato per aree uguali
                const groupByArea = result.data.reduce((group: any, area: any) => {
                    const { Level_1 } = area;
                    group[Level_1] = group[Level_1] ?? [];
                    group[Level_1].push(area);
                    return group;
                }, {});

                // recupero le chiavi della lista raggruppata (ogni chiave corrisponde al nome di un'area)
                const keys = Object.keys(groupByArea);
                // ciclo le chiavi, e se la chiave corrente è presente nella lista delle aree, costruisco i dati da mostrare in capability map per l'area di riferimento
                keys.forEach(k => {
                    if (areas.includes(k)) {
                        let areaCore = groupByArea[k].filter((row: any) => row.Importance === 'Core');

                        let areaCoreDrivers: Array<number> = [];
                        switch (driver) {
                            case 'overall':
                                areaCoreDrivers = calculateDriverNum(areaCore, 'Driver_Overall');
                                break;
                            case 'obsolescence':
                                areaCoreDrivers = calculateDriverNum(areaCore, 'Driver_Obsolescence');
                                break;
                            case 'coverage':
                                areaCoreDrivers = calculateDriverNum(areaCore, 'Driver_Coverage');
                                break;
                            case 'evolvability':
                                areaCoreDrivers = calculateDriverNum(areaCore, 'Driver_Evolvability');
                                break;
                        }

                        let selectedIndicator = indicator === 'Leading' ? areaCoreDrivers[0] : indicator === 'Basic' ? areaCoreDrivers[1] : areaCoreDrivers[2];

                        let dataArea = {
                            total: groupByArea[k].length,
                            core: areaCore.length,
                            core_perc: Math.round(calculatePercentage(selectedIndicator, areaCore.length))
                        }
                        dataCapMap[k] = dataArea;
                    }
                });

                // verifico se tutte le chiavi hanno una corrispondenza con la lista delle aree
                // altrimenti ciclo le chiavi mancanti e per l'area di riferimento costruisco dei dati vuoti da mostrare in capability map
                let missingAreas = areas.filter(x => !keys.includes(x));
                missingAreas.forEach(a => {
                    let dataArea = {
                        core: 0,
                        total: 0,
                        core_perc: 0
                    }
                    dataCapMap[a] = dataArea;
                });
                setCapabilityData(dataCapMap);

                // ordino i sistemi core/no core per codice cmdb crescente
                const resCore = sortByCMDB(core);
                const resNoCore = sortByCMDB(noCore);

                // costruisco i dati da mostrare negli esagoni e nel dettaglio laterale
                setDataCore(mapDetailData(resCore, driver));
                setDataNoCore(mapDetailData(resNoCore, driver));
            },
        });
    }, [driver, area, indicator]) // eslint-disable-line react-hooks/exhaustive-deps

    function calculateSum(array: Array<any>, property: string) {
        return array.reduce((accumulator: number, row: any) => {
            return accumulator + checkEmptyNumber(row[property]);
        }, 0);
    }

    function calculatePercentage(driver: number, total: number) {
        return driver === 0 ? 0 : driver / total * 100;
    }

    function calculateDriverNum(array: Array<any>, property: string) {
        let leading = array.filter((row: any) => row[property] === 'Leading').length;
        let basic = array.filter((row: any) => row[property] === 'Basic').length;
        let lagging = array.filter((row: any) => row[property] === 'Lagging').length;
        return [leading, basic, lagging];
    }

    function sortByCMDB(array: Array<any>) {
        return array.sort((a: any, b: any) => (a.CMDB_ID > b.CMDB_ID) ? 1 : -1)
    }

    function mapDetailData(data: Array<any>, driver: string) {
        return data.map((data: any) => {
            let app: any = {
                name: checkEmptyString(data.Service_Name),
                cmdb: checkEmptyString(data.CMDB_ID),
                driver: driver === 'obsolescence' ? data.Driver_Obsolescence : driver === 'coverage' ? data.Driver_Coverage : driver === 'evolvability' ? data.Driver_Evolvability : data.Driver_Overall,
                importance: data.Importance,
                location: data.Location,
                description: checkEmptyString(data.Description),
                capex: checkEmptyNumber(data.CAPEX_Sistema),
                opex: checkEmptyNumber(data.OPEX_Sistema),
                owner: checkEmptyString(data.Property),
                responsible: checkEmptyString(data.Organization_Responsable),
                drAvailable: checkEmptyString(data.DR_Available),
                ongoingInitiatives: checkEmptyArray(data.Iniziative_Ongoing)
            }
            return app;
        })
    }

    function checkEmptyString(field: string) {
        return field === '' || field === ' ' ? 'No Data' : field;
    }

    function checkEmptyNumber(field: string) {
        return field === '' || field === ' ' ? 0 : parseInt(field);
    }

    function checkEmptyArray(array: string) {
        return array === '' || array === ' ' ? [] : JSON.parse(array);
    }

    function setActive(selectedDriver: string, driver: string, cssClass: string, indicator: string) {
        return selectedDriver === driver ? cssClass + ' active ' + indicator.toLocaleLowerCase() : cssClass;
    }

    return (
        <div className='wrapper'>
            <div className='header'>
                <div className="logo-border">
                    <div className='mds-logo' onClick={() => { navigate('/home'); playClick(); }}>
                        <Atom />
                    </div>
                </div>
                <div className='navbar'>
                    <div className='nav'>
                        <ul className='breadcrumb'>
                            <li className='li-click' onClick={() => { navigate(-1); playClick(); }}>
                                <Globe />
                                Italy
                            </li>
                            {showCapMap ? <li>Systems</li> :
                                <>
                                    <li>
                                        <a href='#/' onClick={() => backToCapMap()}>Systems</a>
                                    </li>
                                    <li>{area}</li>
                                </>
                            }
                        </ul>
                    </div>
                    <div className='driver-filter'>
                        <DriverParallelogram driverName={'Overall'}
                            className={setActive(driver, 'overall', 'parallelogram', indicatorFlag ? '' : indicator)}
                            onClick={(e) => showSubmenu('overall', e)} icon={<Overall />} />
                        <DriverParallelogram driverName={'Obsolescence'}
                            className={setActive(driver, 'obsolescence', 'parallelogram obsolescence', indicatorFlag ? '' : indicator)}
                            onClick={(e) => showSubmenu('obsolescence', e)} icon={<Obsolescence />} />
                        <DriverParallelogram driverName={'Coverage'}
                            className={setActive(driver, 'coverage', 'parallelogram coverage', indicatorFlag ? '' : indicator)}
                            onClick={(e) => showSubmenu('coverage', e)} icon={<Coverage />} />
                        <DriverParallelogram driverName={'Evolvability'}
                            className={setActive(driver, 'evolvability', 'parallelogram evolvability', indicatorFlag ? '' : indicator)}
                            onClick={(e) => showSubmenu('evolvability', e)} icon={<Evolvability />} />
                    </div>
                    <div className="spacer"></div>
                    <div className="submenu" style={submenuStyle}>
                        <div className="submenu-dropdown">
                            {!showCapMap && <SubmenuItem icon={<All />} itemTitle='All' className={'menu-item all ' + tempDriver} onClick={() => selectIndicator('All', true)} />}
                            <SubmenuItem icon={<Leading />} itemTitle='Leading' className={'menu-item leading ' + tempDriver} onClick={() => selectIndicator('Leading', false)} />
                            <SubmenuItem icon={<Basic />} itemTitle='Basic' className={'menu-item basic ' + tempDriver} onClick={() => selectIndicator('Basic', false)} />
                            <SubmenuItem icon={<Lagging />} itemTitle='Lagging' className={'menu-item lagging ' + tempDriver} onClick={() => selectIndicator('Lagging', false)} />
                        </div>
                    </div>
                </div>
            </div>
            {showCapMap ?
                <CapabilityMap fromCapMap_area={fromCapMap_area} fromCapMap_showDetail={fromCapMap_showDetail} fromItApp_siderData={siderData} fromItApp_capabilityData={capabilityData} fromItApp_selectedArea={area} fromItApp_capMapClass={capMapClass} fromItApp_indicator={indicator} fromItApp_contentClass={contentClass} /> :
                <Detail fromItApp_siderData={siderData} fromItApp_dataCore={dataCore} fromItApp_dataNoCore={dataNoCore} fromItApp_indicator={indicator} fromItApp_unsetIndicator={indicatorFlag} fromItApp_contentClass={contentClass} fromItApp_bannerClass={bannerClass} />
            }
        </div>
    );
}

export default ItApplication;